import React from 'react'
import styled from 'styled-components'

import {buildImageObj, cn, toPlainText} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import {colors, font, fontfamily} from '../styles/variables'

import Heading from './heading'
import Button from './button'

const Wrapper = styled.article`
  display: block;
  padding: 4rem 0;
`

const ImageWrapper = styled.div`
  display: block;
  margin: 0 2.4rem 4rem 2.4rem;
`

const Image = styled.img`
  display: block;
  border-radius: 5px;
  box-shadow: 0 4px 18px 0 rgba(0,0,0,0.15);
`

const Text = styled.div`
  margin: 0 2.4rem 4rem 2.4rem;
`

const About = props => {
  const {title, titleEn, _rawBody} = props
  return (
    <Wrapper>
      <Heading title={title} titleEn={titleEn} />

      {_rawBody && (
        <Text>
          <PortableText blocks={_rawBody} />
        </Text>
      ) }

      <Button link='/about/' label='学園についてへ戻る' />
    </Wrapper>
  )
}
export default About
